<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    components: {
        Header,
        SideBar,
    },
    data() {
        return {
            transactions: [],
            filteredTransactions: [],
            loading: false,
            currentPage: 1,
            totalPages: 1,
            perPage: 15,
            clientId: "",
            startDate: "",
            endDate: "",
            selectedOperateur: "",
            selectedStatut: "",
        };
    },
    mounted() {
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.loadTransactions();
            } catch (error) {
                console.error("Erreur lors du parsing de userData:", error);
            }
        }
    },

    methods: {
        async loadTransactions(page = 1) {
            this.loading = true;
            try {
                if (this.clientId) {
                    const params = {
                        page,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        operateur: this.selectedOperateur,
                        statut: this.selectedStatut,
                    };
                    const response = await axios.get(`/transactions/client/${this.clientId}`, {
                        params
                    });
                    this.transactions = response.data.transactions.data;
                    this.currentPage = response.data.transactions.current_page;
                    this.totalPages = response.data.transactions.last_page;
                    this.filterTransactions();
                } else {
                    Swal.fire("Erreur", "Client ID introuvable", "error");
                }
            } catch (error) {
                console.error("Erreur lors du chargement des transactions:", error);
            } finally {
                this.loading = false;
            }
        },
        selectPaylink(paylink) {
            this.selectedPaylink = paylink;
            this.filterTransactions();
        },

        filterTransactions() {
            if (this.selectedPaylink) {
                this.filteredTransactions = this.transactions.filter(transaction => transaction.paylink_id === this.selectedPaylink);
            } else {
                this.filteredTransactions = this.transactions;
            }
            this.totalPages = Math.ceil(this.filteredTransactions.length / this.perPage);
            this.currentPage = 1;
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.loadTransactions(page);
            }
        },

        getClientIdFromSession() {
            const userDataString = sessionStorage.getItem("userData");
            if (userDataString) {
                try {
                    const userData = JSON.parse(userDataString);
                    return userData.additional_info.id;
                } catch (error) {
                    console.error("Erreur lors du parsing de userData depuis sessionStorage:", error);
                }
            }
            return null;
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">

                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block custom-block-transation-detail bg-white">
                            <div class="d-flex flex-wrap align-items-center border-bottom pb-3 mb-3">
                                <div class="d-flex align-items-center">
                                    <img src="images/profile/young-woman-with-round-glasses-yellow-sweater.jpg" class="profile-image img-fluid" alt="">

                                    <div>
                                        <p>Paylinks</p>

                                        <small class="text-muted">Payment Received</small>
                                    </div>
                                </div>

                                <div class="ms-auto">
                                    <small>22/8/2023</small>
                                    <strong class="d-block text-success"><span class="me-1">+</span> $280</strong>
                                </div>
                            </div>

                            <div class="d-flex flex-wrap align-items-center">
                                <div class="custom-block-transation-detail-item mt-4">
                                    <h6>Transation ID</h6>

                                    <p>283J3S0EL023</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 mx-auto px-4">
                                    <h6>Description</h6>

                                    <p>Shopee</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 ms-lg-auto px-lg-3 px-md-3">
                                    <h6>Payment Type</h6>

                                    <p>C2C Transfer</p>
                                </div>

                                <div class="custom-block-transation-detail-item mt-4 ms-auto me-auto">
                                    <h6>Amounts</h6>

                                    <p>$280</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES TRANSACTIONS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white; text-align: center;">N°</th>
                                            <th scope="col" style="color: white; text-align: center;">DATE</th>
                                            <th scope="col" style="color: white; text-align: center;">EXPÉDITEUR</th>
                                            <th scope="col" style="color: white; text-align: center;">MONTANT</th>
                                            <th scope="col" style="color: white; text-align: center;">GSM</th>
                                            <th scope="col" style="color: white; text-align: center;">FONCTION</th>
                                            <th scope="col" style="color: white; text-align: center;">PAIEMENT</th>
                                            <th scope="col" style="color: white; text-align: center;">STATUT</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-if="loading">
                                            <td colspan="6" class="text-center">
                                                <div class="spinner-border text-success" role="status">
                                                    <span class="sr-only">Chargement en cours...</span>
                                                </div>
                                                <p>Chargement en cours...</p>
                                            </td>
                                        </tr>

                                        <tr v-if="!loading && filteredTransactions.length === 0">
                                            <td colspan="6" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                    Aucune donnée disponible.
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-for="(transaction, index) in filteredTransactions" :key="transaction.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ new Date(transaction.created_at).toLocaleDateString() }}</td>
                                            <td>{{ transaction.nomdestinataire }}</td>
                                            <td>{{ transaction.montant }}</td>

                                            <td>
                                                <img v-if="transaction.operateur === 'MTN'" src="/../../images/MOMOPAY.png" alt="MTN" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'MOOV'" src="/../../images/MOOVFLOOZ.png" alt="MOOV" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'CELTIIS'" src="/../../images/LOGOCELTIS.png" alt="CELTIIS" style="width: 35px; height: 34px" />
                                                <span v-else>{{ transaction.operateur }}</span>
                                            </td>

                                            <td>{{ transaction.fonction }}</td>
                                            <td>{{ transaction.payment_type }}</td>
                                            <td>
                                                <span v-if="transaction.status === 'PENDING'" class="badge text-bg-warning">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'SUCCESSFUL'" class="badge text-bg-success">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'FAILED'" class="badge text-bg-danger">
                                                    {{ transaction.status }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent="prevPage" :disabled="currentPage === 1">Précédent</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent="nextPage" :disabled="currentPage === totalPages">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style></style>
